@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.w-130px {
  width: 130px;
}

.uppy-DashboardTab {
  height: 100% !important;
  width: initial !important;
  border-bottom: none !important;
  display: inline-block !important;
}

.uppy-DashboardTab svg {
  width: 100px !important;
  height: 100px !important;
  margin-right: 0 !important;
}

.uppy-Webcam-button--picture svg,
.uppy-DashboardTab svg path {
  fill: #2a6ff5;
}

.uppy-Webcam-button--picture:hover svg {
  fill: #2360d7 !important;
}

.uppy-Webcam-button--picture {
  border-radius: 5px !important;
  padding: 10px !important;
  background-color: #dbeafe !important;
}

.uppy-Webcam-button--picture:hover {
  background-color: #bfdbfe !important;
}

.uppy-Webcam-button--picture svg {
  width: 30px !important;
  height: 24px !important;
}

.uppy-DashboardTab-btn {
  width: 116px !important;
  height: 100px !important;
  padding: 10px !important;
  border-radius: 10px !important;
  flex-direction: column !important;
  background-color: #dae6ff !important;
}

.uppy-Dashboard-AddFiles-list > .uppy-DashboardTab:first-child > .uppy-DashboardTab-btn {
  margin-right: 16px !important;
}

.uppy-ProviderIconBg {
  fill: none !important;
}

.uppy-Webcam-videoContainer {
  position: relative !important;
}

.uppy-StatusBar-statusPrimary {
  color: #082051 !important;
}

.uppy-StatusBar-actionBtn--done {
  color: #2a6ff5 !important;
}

.uppy-Webcam-footer {
  position: absolute !important;
  bottom: 10px !important;
  right: 10px !important;
}

.uppy-Dashboard-AddFiles {
  height: auto !important;
  margin: 0 !important;
  border: none !important;
  align-items: unset !important;
}

.uppy-Dashboard-AddFiles-list {
  flex: none !important;
  margin-top: 0 !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.uppy-DashboardTab-name {
  color: #082051;
  font-size: 14px !important;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.uppy-Dashboard-AddFiles-title {
  display: none !important;
}

.uppy-Dashboard-AddFiles-info {
  display: none !important;
}

.uppy-Dashboard-inner {
  width: 100% !important;
  border: none !important;
  height: 320px !important;
  background: none !important;
}

.uppy-DashboardTab-btn:hover {
  background-color: #2360d7 !important;
}

.uppy-DashboardTab-btn:hover .uppy-DashboardTab-name {
  color: white;
}

.uppy-size--md .uppy-Dashboard-AddFiles-list {
  flex-wrap: initial !important;
}

.uppy-Informer-animated p {
  background-color: rgba(231, 76, 60, 1) !important;
}

.blink-border {
  outline: 2px #2360d7 solid;
  animation: blink 1s;
  animation-iteration-count: infinite;
}

[type='checkbox']:checked {
  background-image: url('icons/systemIcons/checkedMarkTailwind.svg');
}

@keyframes blink { 50% { outline-color:#fff; }  }

.PhoneInput {
  border-radius: 0.375rem;
  display: flex;
  height: 34px;
  padding-left: 10px;
  position: relative;
  width: 100%;
}

.PhoneInputInput {
  border: none;
  border-radius: 0.375rem;
  font-size: 14px;
  height: 100%;
  line-height: 1rem;
  padding: 0.3rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  width: 50%;
}

.PhoneInputInput:focus {
  box-shadow: 0 0 0 0 #ffffff;
}

.PhoneInputCountrySelectArrow {
  border: 1px solid;
  border-top-width: 0;
  border-left-width: 0;
  height: 7px;
  margin-left: 10px;
  margin-top: -2px;
  width: 7px;
}

@media screen and (min-width: 640px) {
  .PhoneInput {
    border-radius: 0.375rem;
    height: 38px;
    padding-left: 17px;
    width: 150px;
  }
  .PhoneInputInput {
    font-size: 0.875rem;
    height: 100%;
    line-height: 1.25rem;
    padding: 0.7rem;
  }
  .PhoneInputCountrySelectArrow {
    border: 1px solid;
    border-top-width: 0;
    border-left-width: 0;
    height: 10px !important;
    margin-left: 14px !important;
    margin-top: -5px !important;
    width: 10px !important;
  }
}

@media screen and (min-width: 380px) {
  .uppy-DashboardTab-btn {
    width: 150px !important;
    height: auto !important;
  }
}

@media screen and (min-width: 500px) {
  .uppy-Dashboard-AddFiles-list {
    justify-content: flex-start !important;
  }
  .uppy-DashboardTab-btn {
    width: 189px !important;
    height: auto !important;
  }

  .uppy-Dashboard-AddFiles-list > .uppy-DashboardTab:first-child > .uppy-DashboardTab-btn {
    margin-right: 32px !important;
  }
}

