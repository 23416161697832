.dots {
  bottom: 0;
  right: 0;
  margin: auto;
  -webkit-filter: url(#goo);
  filter: url(#goo);
}

.dot,
.dots {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.dot:before {
  content: '';
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background: #fbd301;
  position: absolute;
  left: 50%;
  -webkit-transform: translateY(0) rotate(0deg);
  transform: translateY(0) rotate(0deg);
  margin-left: -17.5px;
  margin-top: -17.5px;
}

@-webkit-keyframes dot-move {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  18%,
  22% {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }

  40%,
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes dot-move {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  18%,
  22% {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
  }

  40%,
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes dot-colors {
  0% {
    background-color: #fbd301;
  }

  25% {
    background-color: #ff3270;
  }

  50% {
    background-color: #208bf1;
  }

  75% {
    background-color: #afe102;
  }

  to {
    background-color: #fbd301;
  }
}

@keyframes dot-colors {
  0% {
    background-color: #fbd301;
  }

  25% {
    background-color: #ff3270;
  }

  50% {
    background-color: #208bf1;
  }

  75% {
    background-color: #afe102;
  }

  to {
    background-color: #fbd301;
  }
}

.dot:nth-child(5):before {
  z-index: 100;
  width: 45.5px;
  height: 45.5px;
  margin-left: -22.75px;
  margin-top: -22.75px;
  -webkit-animation: dot-colors 4s ease infinite;
  animation: dot-colors 4s ease infinite;
}

@-webkit-keyframes dot-rotate-1 {
  0% {
    -webkit-transform: rotate(-105deg);
    transform: rotate(-105deg);
  }

  to {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes dot-rotate-1 {
  0% {
    -webkit-transform: rotate(-105deg);
    transform: rotate(-105deg);
  }

  to {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

.dot:first-child {
  -webkit-animation: dot-rotate-1 4s linear 0s infinite;
  animation: dot-rotate-1 4s linear 0s infinite;
}

.dot:first-child:before {
  background-color: #ff3270;
  -webkit-animation: dot-move 4s ease 0s infinite;
  animation: dot-move 4s ease 0s infinite;
}

@-webkit-keyframes dot-rotate-2 {
  0% {
    -webkit-transform: rotate(165deg);
    transform: rotate(165deg);
  }

  to {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }
}

@keyframes dot-rotate-2 {
  0% {
    -webkit-transform: rotate(165deg);
    transform: rotate(165deg);
  }

  to {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }
}

.dot:nth-child(2) {
  -webkit-animation: dot-rotate-2 4s linear 1s infinite;
  animation: dot-rotate-2 4s linear 1s infinite;
}

.dot:nth-child(2):before {
  background-color: #208bf1;
  -webkit-animation: dot-move 4s ease 1s infinite;
  animation: dot-move 4s ease 1s infinite;
}

@-webkit-keyframes dot-rotate-3 {
  0% {
    -webkit-transform: rotate(435deg);
    transform: rotate(435deg);
  }

  to {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg);
  }
}

@keyframes dot-rotate-3 {
  0% {
    -webkit-transform: rotate(435deg);
    transform: rotate(435deg);
  }

  to {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg);
  }
}

.dot:nth-child(3) {
  -webkit-animation: dot-rotate-3 4s linear 2s infinite;
  animation: dot-rotate-3 4s linear 2s infinite;
}

.dot:nth-child(3):before {
  background-color: #afe102;
  -webkit-animation: dot-move 4s ease 2s infinite;
  animation: dot-move 4s ease 2s infinite;
}

@-webkit-keyframes dot-rotate-4 {
  0% {
    -webkit-transform: rotate(705deg);
    transform: rotate(705deg);
  }

  to {
    -webkit-transform: rotate(3turn);
    transform: rotate(3turn);
  }
}

@keyframes dot-rotate-4 {
  0% {
    -webkit-transform: rotate(705deg);
    transform: rotate(705deg);
  }

  to {
    -webkit-transform: rotate(3turn);
    transform: rotate(3turn);
  }
}

.dot:nth-child(4) {
  -webkit-animation: dot-rotate-4 4s linear 3s infinite;
  animation: dot-rotate-4 4s linear 3s infinite;
}

.dot:nth-child(4):before {
  background-color: #fbd301;
  -webkit-animation: dot-move 4s ease 3s infinite;
  animation: dot-move 4s ease 3s infinite;
}
